<template>
  <div class="realNameAuditForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="手机号码" prop="mobile">
        <span>{{ form.mobile }}</span>
      </el-form-item>

      <el-form-item label="用户昵称" prop="nickName">
        <v-input placeholder="请输入用户昵称" v-model="form.nickName"></v-input>
      </el-form-item>

      <el-form-item label="性别" prop="gender">
        <v-select :options="sexOps" v-model="form.gender" />
      </el-form-item>

      <el-form-item label="身份证件" prop="idCardType">
        <span>{{ form.idCardType }}</span>
      </el-form-item>

      <el-form-item label="证件号码" prop="idCardNum">
        <span>{{ form.idCardNum }}</span>
      </el-form-item>
      <el-form-item label="住址" prop="idCardNum">
        <div class="address">
          <div class="item" v-for="(item, index) in houseNameList" :key="index">
            <div class="item-l">{{ item.houseName }}</div>
            <div class="item-r">
              <v-button text="退出" v-if="item.assetId" @click="outRoom(item)" type="warning"></v-button>
              <v-button v-if="index == houseNameList.length - 1" text="添加" @click="addRoom"></v-button>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="车位号">
        <div class="address">
          <div class="item" v-for="(item, index) in carportList" :key="index">
            <div class="item-l">{{ item.spaceName }}</div>
            <div class="item-r">
              <v-button text="删除" v-if="item.id" @click="outCarport(item)" type="warning"></v-button>
              <v-button v-if="index == carportList.length - 1" text="添加" @click="addCarport"></v-button>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="个性标签" class="formItem">
        <v-tag class="vTag" :text="item" v-for="(item, index) in form.labelNames" :key="index"></v-tag>
      </el-form-item>

      <el-form-item label="订单数量" prop="orderCount">
        <span>{{ form.orderCount }}</span>
      </el-form-item>
      <el-form-item label="可用积分" prop="availablePoint">
        <span>{{ form.availablePoint }}</span>
      </el-form-item>
      <el-form-item label="车牌号">
        <div class="address">
          <div class="item" v-for="(item, index) in carinfoList" :key="index">
            <div class="item-l">{{ item.carNum }}</div>
            <div class="item-r">
              <v-button text="删除" v-if="item.id" @click="outCarinfo(item)" type="warning"></v-button>
              <v-button v-if="index == carinfoList.length - 1" text="添加" @click="addCarinfo"></v-button>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="关联用户标签">
        <!--  若复制该代码， extraClass值需要修改 -->
        <v-cascader
          ref="vCascader"
          extraClass="userManageForm_cascader-v-1828066a"
          v-loading="userLabelLoading"
          placeholder="请选择"
          :width="width"
          v-model="userLabelIds"
          :options="userSectionOps"
          :props="userProps"
        >
          <template slot-scope="{ node, data }">
            <span class="node-flex">
              <span class="node-flex_label">{{ data.name }}</span>
              <span class="node-flex_remark">{{ data.remark }}</span>
              <span class="node-flex_input" v-if="!data.children || data.children.length == 0">
                <v-input
                  width="120"
                  class="remark"
                  size="mini"
                  :value="data.remark"
                  placeholder="可填写备注"
                  @blur="$event => cascaderInput(data, $event, node)"
                ></v-input>
              </span>
            </span>
          </template>
        </v-cascader>
      </el-form-item>
      <el-form-item label="关联商户标签">
        <v-cascader
          v-loading="businessLabelLoading"
          placeholder="请选择"
          :width="width"
          v-model="businessLabelIds"
          :options="businessSectionOps"
          :props="businessProps"
        />
      </el-form-item>
    </form-panel>

    <v-dialog title="添加地址" class="realNameAuditForm-addAddress" v-model="isdialog" @confirm="confirm" cancelTxt="取消" sureTxt="添加">
      <roomLevel ref="roomLevel" @change="changeData"></roomLevel>
    </v-dialog>
    <v-dialog
      title="添加车号"
      class="realNameAuditForm-addAddress"
      v-model="isCarinfoDialog"
      @confirm="CarinfoConfirm"
      cancelTxt="取消"
      sureTxt="添加"
    >
      <v-input label="车牌号" v-model="carParam.carNum" />
    </v-dialog>
    <v-dialog
      title="添加车位"
      class="realNameAuditForm-addAddress"
      v-model="isCarDialog"
      @confirm="confirmCar"
      cancelTxt="取消"
      sureTxt="添加"
    >
      <div class="caritem">
        <v-select clearable :options="userTypeOptions" v-model="carPortParam.userType" label="用户类别" @change="changeUserType" />
      </div>
      <div class="caritem">
        <community-select
          v-if="carPortParam.userType != null && carPortParam.userType"
          v-model="carPortParam.communityId"
          @change="changeCommunit"
          :label="areaOptionsMap[carPortParam.userType]"
          :filterable="true"
        />
      </div>
      <div class="caritem">
        <v-autocomplete
          ref="autocomplete"
          v-if="carPortParam.communityId != null && carPortParam.communityId"
          label="车位号"
          v-model="carPortParam.spaceName"
          @change="changeCar($event)"
          :querySearchAsync="querySearchAsync"
        ></v-autocomplete>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getUserDetailURL,
  registerURL,
  roomListUrl,
  addRoomListUrl,
  outRoomListUrl,
  getCarportListURL,
  getUserCarListURL,
  getUserCarportListURL,
  addUserCarportUrl,
  addUserCarInfoUrl,
  deleteUserCarportUrl,
  deleteUserCarInfoUrl,
  getLabelTreeListUrl,
  getUserLabelRelationListUrl,
  saveUserLabelRelationUrl
} from './api.js';
import { auditStatusMap, setAuditStatusOps, sexMap, setSexOps, userTypeOptions, userTypeOptionsMap, areaOptionsMap } from './map.js';
import roomLevel from '@/components/bussiness/roomLevel/roomLevel.vue';
export default {
  name: 'placeEdit',
  components: {
    roomLevel
  },
  data() {
    return {
      width: 250,
      isCarinfoDialog: false,
      carinfoList: [],
      carPortListops: [],

      areaOptionsMap,
      carParam: {
        carNum: '',
        userId: ''
      },
      carPortParam: {
        auditStatus: 1,
        userType: null,
        communityId: '',
        relatedSpaceId: '',
        spaceName: '',
        userId: ''
      },
      userTypeOptions,
      isCarDialog: false,
      carportList: [],
      isdialog: false,
      uploadURL: '',
      submitConfig: {
        queryUrl: getUserDetailURL,
        submitUrl: registerURL
      },
      auditStatusMap,
      auditStatusOps: setAuditStatusOps(2),
      sexMap,
      sexOps: setSexOps(2),
      form: {
        tenantId: '',
        avatarUrl: '',
        id: '',
        mobile: '',
        nickName: '',
        gender: '',
        idCardType: '',
        idCardNum: '',
        orderCount: '',
        availablePoint: '',
        labelNames: ''
      },
      houseNameList: [],
      searchParam: {
        userType: null,
        spaceId: null,
        identity: null,
        mobile: null,
        nickName: null,
        gender: null,
        auditStatus: null,
        communityId: null
      },

      /** v-cascader */
      labelFormList: [
        {
          labelType: 1, // 用户标签
          userId: '',
          relatedLabelIds: [],
          relatedLabelRemarks: []
        },
        {
          labelType: 2, // 商户标签
          userId: '',
          relatedLabelIds: []
        }
      ],
      userLabelLoading: false,
      userLabelIds: [],
      userSectionOps: [], // 用于显示的数据
      userLabelPathIds: [], // 添加treeIds的数据
      userProps: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'children'
      },

      businessLabelLoading: false,
      businessLabelIds: [],
      businessSectionOps: [], // 用于显示的数据
      businessLabelPathIds: [], // 添加treeIds的数据
      businessProps: {
        multiple: false,
        label: 'name',
        value: 'id',
        children: 'children'
      }
      /** end */
    };
  },
  watch: {
    userLabelIds: {
      handler(val) {
        this.labelFormList[0].relatedLabelIds = val.map(a => a[a.length - 1]);
      },
      deep: true
    },

    businessLabelIds: {
      handler(val) {
        this.labelFormList[1].relatedLabelIds = val.length > 0 ? [val[val.length - 1]] : [];
      },
      deep: true
    }
  },
  async mounted() {
    const { id, tenantId } = this.$route.query;
    this.form.id = id;
    this.form.tenantId = tenantId;
    this.carPortParam.userId = id;

    this.labelFormList.forEach(v => {
      v.userId = id;
    });

    if (id !== undefined) {
      this.$refs.formPanel.getData({ userId: id });
      this.handleRoomUrl();
      this.getCarportList();
      this.getCarinfoList();
      await Promise.all([
        this.getLabelTreeList(id, 1, {
          loading: 'userLabelLoading',
          sectionOps: 'userSectionOps',
          pathIds: 'userLabelPathIds'
        }),
        this.getLabelTreeList(id, 2, {
          loading: 'businessLabelLoading',
          sectionOps: 'businessSectionOps',
          pathIds: 'businessLabelPathIds'
        })
      ]);
      this.getUserLabelRelationList(id);
    }
    this.$setBreadList(id ? '编辑' : '新增');
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      console.log(queryString, cb);
      let results = await this.getCarPortListops();
      cb(results);
    },
    changeCar(value, e) {
      console.log(this.carPortListops, value, e);
      // this.carPortListops.forEach((ele) => {
      //   if (ele.value == value) {
      //     this.carPortParam.spaceName = ele.label;
      //   }
      // });
      this.carPortParam.relatedSpaceId = value.label;
    },
    changeCommunit() {
      this.carPortParam.spaceName = '';
      this.carPortParam.relatedSpaceId = '';
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.reset();
      }
    },
    changeUserType() {
      this.carPortParam.communityId = '';
      this.carPortParam.spaceName = '';
      this.carPortParam.relatedSpaceId = '';
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.reset();
      }
    },

    async getCarPortListops() {
      let params = {
        communityId: this.carPortParam.communityId,
        spaceName: this.carPortParam.spaceName
      };
      this.carPortListops = [];
      let res = await this.$axios.get(`${getCarportListURL}`, { params });
      if (res.code === 200) {
        res.data.records.forEach(item => {
          let obj = {};
          obj.label = item.spaceId;
          obj.value = item.name;
          this.carPortListops.push(obj);
        });
        return this.carPortListops;
      } else {
      }
    },
    outCarinfo(item) {
      this.$confirm('确定要删除？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {};
          this.$axios.post(`${deleteUserCarInfoUrl}?id=${item.id}`, params).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功');
              this.getCarinfoList();
            } else {
            }
          });
        })
        .catch(() => {});
    },
    async CarinfoConfirm() {
      if (!this.carParam.carNum) {
        this.$message('请输入车牌号');
        return;
      }
      let params = {
        carNum: this.carParam.carNum,
        userId: this.form.id
      };
      let res = await this.$axios.post(`${addUserCarInfoUrl}`, params);
      if (res.code === 200) {
        this.$message.success('操作成功');
        this.isCarinfoDialog = false;
        this.carParam = {
          carNum: '',
          userId: ''
        };
        this.getCarinfoList();
      } else {
      }
    },
    async confirmCar() {
      if (!this.carPortParam.relatedSpaceId) {
        this.$message('请选择车位');
        return;
      }
      let params = {
        auditStatus: 1,
        communityId: this.carPortParam.communityId,
        relatedSpaceId: this.carPortParam.relatedSpaceId,
        spaceName: this.carPortParam.spaceName,
        userId: this.form.id
      };
      let res = await this.$axios.post(`${addUserCarportUrl}`, params);
      if (res.code === 200) {
        this.$message.success('操作成功');
        this.isCarDialog = false;
        this.carPortParam = {
          auditStatus: 1,
          userType: null,
          communityId: '',
          relatedSpaceId: '',
          spaceName: '',
          userId: ''
        };
        this.getCarportList();
      } else {
      }
    },
    addCarinfo() {
      this.isCarinfoDialog = true;
    },
    addCarport() {
      this.isCarDialog = true;
    },
    outCarport(item) {
      this.$confirm('确定要退出？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {};
          this.$axios.post(`${deleteUserCarportUrl}?id=${item.id}`, params).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功');
              this.getCarportList();
            } else {
            }
          });
        })
        .catch(() => {});
    },
    outRoom(item) {
      this.$confirm('确定要退出？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            assetId: item.assetId,
            communityId: item.communityId,
            spaceId: item.houseId,
            userId: this.form.id
          };
          this.$axios.post(`${outRoomListUrl}`, params).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功');
              this.handleRoomUrl();
            } else {
            }
          });
        })
        .catch(() => {});
    },
    changeData(value) {
      this.searchParam.userType = value.userType;
      this.searchParam.identity = value.identity;
      this.searchParam.spaceId = value.spaceId;
      this.searchParam.communityId = value.communityId;
    },
    confirm() {
      let params = {
        communityId: this.searchParam.communityId,
        gender: sexMap[this.form.gender],
        identity: this.searchParam.identity,
        userId: this.form.id,
        mobile: this.form.mobile,
        realName: this.form.nickName,
        spaceId: this.searchParam.spaceId,
        assetAuditStatus: 1
      };
      this.$axios.post(`${addRoomListUrl}`, params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          this.$refs.roomLevel.changeUserType();
          this.isdialog = false;
          this.handleRoomUrl();
        } else {
        }
      });
    },
    addRoom() {
      this.isdialog = true;
    },
    handleRoomUrl() {
      this.houseNameList = [];
      let params = {
        userId: this.form.id,
        tenantId: this.form.tenantId
      };
      this.$axios.post(`${roomListUrl}`, params).then(res => {
        if (res.code === 200) {
          if (res.data.length != 0) {
            this.houseNameList = res.data;
          } else {
            this.houseNameList = [{ houseName: '' }];
          }
        } else {
        }
      });
    },
    async getCarportList() {
      this.carportList = [];
      let params = {
        userId: this.form.id
      };
      let res = await this.$axios.get(`${getUserCarportListURL}`, { params });
      if (res.code === 200) {
        if (res.data.length != 0) {
          this.carportList = res.data;
        } else {
          this.carportList = [{ spaceName: '' }];
        }
      } else {
      }
    },
    async getCarinfoList() {
      this.carinfoList = [];
      let params = {
        userId: this.form.id
      };
      let res = await this.$axios.get(`${getUserCarListURL}`, { params });
      if (res.code === 200) {
        if (res.data.length != 0) {
          this.carinfoList = res.data;
        } else {
          this.carinfoList = [{ spaceName: '' }];
        }
      } else {
      }
    },
    update(data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key] || this.form[key];
      });
    },
    async submitBefore() {
      let result = await this.saveUserLabelRelation();
      if (!result) {
        return;
      }
      return {
        nickName: this.form.nickName,
        gender: sexMap[this.form.gender],
        avatarUrl: this.form.avatarUrl,
        userId: this.form.id
      };
    },
    submitSuccess() {
      return true;
    },

    /* 用户标签 */
    async saveUserLabelRelation() {
      const nodes = this.$refs.vCascader.getCheckedNodes();
      let remarks = [];
      this.labelFormList[0].relatedLabelIds.forEach((v, i) => {
        nodes.forEach(node => {
          if (node.value == v) {
            remarks[i] = node.data.remark || '';
          }
        });
      });
      this.labelFormList[0].relatedLabelRemarks = remarks;

      let res = await this.$axios.post(saveUserLabelRelationUrl, {
        labelFormList: this.labelFormList
      });
      let result = false;
      if (res.code === 200) {
        result = true;
      }
      return result;
    },
    // 回显用户、商户标签
    async getUserLabelRelationList(userId) {
      this.userLabelIds = [];
      this.businessLabelIds = [];
      let res = await this.$axios.get(getUserLabelRelationListUrl, {
        params: {
          // labelType,
          userId
        }
      });
      if (res.code === 200) {
        const { cusLabelIdList, cusLabelRemarkList, bizLabelIdList } = res.data;
        // 用户标签
        let userLabelIds = [];
        const cusIds = cusLabelIdList;
        this.userLabelPathIds.forEach(item => {
          if (cusIds.includes(item.id)) {
            userLabelIds.push([...item.treeIds]);
          }
        });
        this.userLabelIds = [...userLabelIds];
        // 用户标签级联选择器自动选择后
        this.$nextTick(() => {
          let nodes = this.$refs.vCascader.getCheckedNodes();
          console.log(nodes);
          cusLabelIdList.forEach((v, i) => {
            nodes.forEach(node => {
              if (v == node.value) {
                this.$set(node.data, 'remark', cusLabelRemarkList[i]);
              }
            });
          });
        });
        // 商户标签
        let businessLabelIds = [];
        const bizIds = bizLabelIdList;
        this.businessLabelPathIds.forEach(item => {
          if (bizIds.includes(item.id)) {
            // businessLabelIds.push([...item.treeIds]);
            businessLabelIds = [...item.treeIds];
          }
        });
        this.businessLabelIds = [...businessLabelIds];
      }
    },
    // 获取标签选项树
    async getLabelTreeList(userId, labelType, prop) {
      this[prop.loading] = true;
      let res = await this.$axios.get(getLabelTreeListUrl, {
        params: {
          labelType,
          userId // 商户标签id，商户标签编辑页面的场合必传
        }
      });
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data, prop);
        this[prop.sectionOps] = res.data;
      }
      this[prop.loading] = false;
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, prop, ids = []) {
      data.forEach(v => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, prop, [...ids, v.id]);
        } else {
          this[prop.pathIds].push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    cascaderInput(data, ev) {
      console.log(ev, 'ev---');
      this.$set(data, 'remark', ev.target.value);
    }
    /** end */
  }
};
</script>
<style lang="less" scoped>
.realNameAuditForm {
  box-sizing: border-box;
  height: 100%;
  .formItem {
    ::v-deep .el-form-item__content {
      display: block;
      .vTag {
        margin-right: 20px;
      }
    }
  }
  .address {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: auto;
      }
      .item-r {
        margin-left: 20px;
        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .caritem {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less">
.realNameAuditForm-addAddress .v-control {
  display: flex;
  align-items: center;
}
.realNameAuditForm-addAddress .v-control label {
  width: 80px !important;
}

/* 用户标签 v-cascader */
.userManageForm_cascader-v-1828066a .node-flex {
  display: flex;
  align-items: center;
  .node-flex_label {
    display: inline-block;
  }
  .node-flex_input {
    display: none;
    flex: 1;
    text-align: right;
    margin-left: 8px;
  }
  .node-flex_remark {
    margin-left: 8px;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    .node-flex_input {
      display: block;
    }
    .node-flex_remark {
      display: none;
    }
  }
}
/** end */
</style>
